import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const bloqifyConfig = {
  apiKey: process.env.VUE_APP_BLOQIFY_FIREBASE_API_KEY as string,
  authDomain: process.env.VUE_APP_BLOQIFY_FIREBASE_AUTH_DOMAIN as string,
  databaseURL: process.env.VUE_APP_BLOQIFY_FIREBASE_DATABASE_URL as string,
  projectId: process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.VUE_APP_BLOQIFY_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: parseInt(process.env.VUE_APP_BLOQIFY_FIREBASE_MESSAGING_SENDER_ID as string, 10),
};

const bloqify = firebase.initializeApp(bloqifyConfig);

const bloqifyAuth = bloqify.auth();
const bloqifyStorage = bloqify.storage();
const bloqifyFirestore = bloqify.firestore();

// Special region for local test
const bloqifyFunctions: firebase.functions.Functions = bloqify.functions(
  process.env.VUE_APP_BLOQIFY_FIREBASE_CLOUD_FUNCTIONS_REGION,
);

// Emulator for local tests
if (process.env.NODE_ENV === 'development') {
  bloqifyFunctions.useFunctionsEmulator('http://localhost:5001');
}

export {
  bloqify,
  bloqifyFirestore,
  bloqifyAuth,
  bloqifyFunctions,
  bloqifyStorage,
  firebase,
};
