import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase, bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { Investor } from '@/models/users/User';
import { PaymentStatus, PaymentProvider, Investment, Payment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import { assetChecks } from './asset';
import { ceilNumber } from '../utils/numbers';

const SET_PAYMENT = 'SET_PAYMENT';

// Payload types
export type CreatePaymentPayload = {
  amount: number,
  assetId: string,
  investorId: string,
  paymentDateTime: number,
  dividendsFormat?: Payment['dividendsFormat'],
  endDateTime?: number,
  type?: string
}
export type UpdatePaymentPayload = CreatePaymentPayload & {
  investmentId: string,
  paymentId: string
}

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_PAYMENT](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createPayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        type,
      }: CreatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'createPayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          // Collection references
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          let investmentRef = investmentsRef.doc();
          let paymentRef = investmentRef.collection('payments').doc();

          const [readAssetError, readAssetSuccess] = await to(transaction.get(assetRef));
          if (readAssetError || !readAssetSuccess?.exists) {
            throw readAssetError || Error('Asset not found.');
          }

          const asset = readAssetSuccess!.data() as Asset;

          if (!assetChecks(asset)) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }

          const investor = readInvestorSuccess!.data() as Investor;

          const {
            sharePrice, euroMin, sharesAvailable, emissionCost, excessEmission, endDateTime,
            returnsAfterEnd, fixedDividends, startDateTime, premium,
          } = asset;

          if (amount % sharePrice !== 0) {
            throw Error(`The amount has to be a total of shares bought (price per share: ${sharePrice} eur).`);
          }

          const sharesDiff = amount / sharePrice;
          if (sharesDiff > sharesAvailable) {
            throw Error('The asset does not have this many available shares.');
          }

          // Client side we cannot use transaction to get DocumentQueries
          const [getInvestmentError, getInvestmentSuccess] = await to(
            investmentsRef.where('asset', '==', assetRef).where('investor', '==', investorRef).get(),
          );
          if (getInvestmentError) {
            throw getInvestmentError;
          }

          const foundInvestments = !getInvestmentSuccess?.empty;
          const investmentDoc = foundInvestments ? getInvestmentSuccess!.docs[0] : undefined;
          let firstInvestment = false;

          if (foundInvestments) {
            investmentRef = investmentDoc!.ref;
            const [getPaymentsError, getPaymentsSuccess] = await to(
              investmentRef.collection('payments').get(),
            );
            if (getPaymentsError) {
              throw getPaymentsError;
            }
            if (getPaymentsSuccess!.empty) {
              throw Error('There was an error retrieving previous investments information.');
            }
            firstInvestment = !getPaymentsSuccess!.docs.some((snap): boolean => snap.get('providerData.status') === PaymentStatus.Paid);

            // Reset paymentRef
            paymentRef = investmentRef.collection('payments').doc();
          }

          // Only check the minimum amount if it is the first investment
          if (((foundInvestments && firstInvestment) || !foundInvestments) && amount < euroMin) {
            throw Error(`The amount has to be higher than the minimum (${euroMin} eur).`);
          }

          if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime) < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
          } else if (!premium && !dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const dateNow = firebase.firestore.Timestamp.now();

          // Adding the emission cost on top of the euro amount
          let applicableEmission = emissionCost;
          // Use the excess emission percentage only if it is provided and the amount exceeds its threshold
          if (excessEmission && amount >= excessEmission.threshold) {
            applicableEmission = excessEmission.percentage;
          }
          const amountWithEmissionCost = amount * ((100 + applicableEmission) / 100);

          const newPayment: Payment = {
            asset: assetRef,
            investment: investmentRef,
            investor: investorRef,
            createdDateTime: dateNow,
            paymentDateTime: paymentDate,
            id: paymentRef.id,
            provider: PaymentProvider.Custom,
            updatedDateTime: dateNow,
            deleted: false,
            dividendsFormat: !fixedDividends
              ? dividendsFormat!
              : [years.toString(), returnsAfterEnd!],
            providerData: {
              id: paymentRef.id,
              amount: {
                currency: 'EUR',
                value: ceilNumber(amountWithEmissionCost, 2),
              },
              metadata: {
                uid: investorId,
                euroAmount: Number(amount),
                sharesAmount: Number(amount) / sharePrice,
                investmentId: investmentRef.id,
                assetId: assetRef.id,
                paymentId: paymentRef.id,
              },
              status: PaymentStatus.Paid,
            },
            ...(paymentEndDateTime && {
              endDateTime: firebase.firestore.Timestamp.fromMillis(
                paymentEndDateTime,
              ),
            }),
            ...(type && { type }),
          };

          // Setting new investment or updating old one
          if (!foundInvestments) {
            const investment: Omit<Investment, 'id'> = {
              asset: assetRef,
              createdDateTime: dateNow,
              updatedDateTime: dateNow,
              investor: investorRef,
              paidEuroTotal: amount,
              boughtSharesTotal: amount / sharePrice,
            };

            transaction.set(investmentRef, investment);
          } else {
            transaction.update(
              investmentRef,
              {
                paidEuroTotal: firebase.firestore.FieldValue.increment(amount),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesDiff),
                updatedDateTime: dateNow,
              },
            );
          }

          transaction.set(paymentRef, newPayment);
          transaction.update(
            assetRef,
            {
              sharesAvailable: firebase.firestore.FieldValue.increment(-sharesDiff),
              updatedDateTime: dateNow,
            },
          );
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            { paidPayments: firebase.firestore.FieldValue.increment(1) },
          );

          const [sendEmailError] = await to(bloqifyFunctions.httpsCallable('sendSharesEmail')({ lang: 'nl', investor, asset }));
          if (sendEmailError) {
            console.error(sendEmailError);
          }

          // Return the target
          return { investmentId: investmentRef.id, paymentId: paymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'createPayment' });
    },
    async updatePayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        investmentId: sourceInvestmentId,
        paymentId: sourcePaymentId,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        type,
      }: UpdatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'updatePayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          // Collection references
          const assetsRef = bloqifyFirestore.collection('assets');
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorsRef = bloqifyFirestore.collection('investors');

          const assetRef = assetsRef.doc(assetId);
          const investmentRef = investmentsRef.doc(sourceInvestmentId);
          const investorRef = investorsRef.doc(investorId);
          const paymentRef = investmentRef.collection('payments').doc(sourcePaymentId);

          // References that will be modified depending on the case for the transaction update/set zone
          let newPaymentRef = paymentRef;
          let newInvestmentRef = investmentRef;

          const [readSourceInvestmentError, sourceInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (readSourceInvestmentError || !sourceInvestmentSuccess?.exists) {
            throw readSourceInvestmentError || Error('Investment not found.');
          }

          const sourceInvestment = sourceInvestmentSuccess!.data() as Investment;

          const differentAsset = sourceInvestment.asset.id !== assetId;
          const differentInvestor = sourceInvestment.investor.id !== investorId;
          const differentInvestment = differentAsset || differentInvestor;
          let targetInvestment: undefined | { id: string, data: Investment };

          // Different investment means we have to check if there is an investment already there
          if (differentInvestment) {
            const [readTargetInvestmentError, targetInvestmentSuccess] = await to(
              investmentsRef.where('asset', '==', assetsRef.doc(assetId)).where('investor', '==', investorsRef.doc(investorId)).get(),
            );
            if (readTargetInvestmentError) {
              throw readTargetInvestmentError || Error('Target investment not found.');
            }

            // Assigning the target investment
            if (!targetInvestmentSuccess!.empty) {
              targetInvestment = {
                id: targetInvestmentSuccess!.docs[0].id,
                data: targetInvestmentSuccess!.docs[0].data() as Investment,
              };
            }
          }

          const [readSourcePaymentError, sourcePaymentSuccess] = await to(transaction.get(paymentRef));
          if (readSourcePaymentError || !sourcePaymentSuccess?.exists) {
            throw readSourcePaymentError || Error('Payment not found.');
          }

          const sourcePayment = sourcePaymentSuccess!.data() as Payment;

          if (sourcePayment.deleted || sourcePayment.ended) {
            throw readSourcePaymentError || Error('Cannot modify an ended or deleted payment.');
          }

          if (sourcePayment.providerData.status !== PaymentStatus.Paid) {
            throw readSourcePaymentError || Error('Cannot modify a payment which is not paid.');
          }

          const [readSourceAssetError, sourceAssetSuccess] = await to(transaction.get(assetRef));
          if (readSourceAssetError || !sourceAssetSuccess?.exists) {
            throw readSourceAssetError || Error('Asset not found.');
          }

          let targetAsset: undefined | Asset;
          if (differentAsset) {
            const [readTargetAssetError, targetAssetSuccess] = await to(transaction.get(assetsRef.doc(assetId)));
            if (readTargetAssetError || !targetAssetSuccess?.exists) {
              throw readTargetAssetError || Error('Target asset not found.');
            }

            targetAsset = targetAssetSuccess!.data() as Asset;
          }

          const sourceAsset = sourceAssetSuccess!.data() as Asset;

          // Check both (if necessary) assets have all fields correct
          if (!assetChecks(sourceAsset) && (!differentAsset || (differentAsset && !assetChecks(targetAsset!)))) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          // Select the correct asset
          const {
            sharePrice, euroMin, sharesAvailable, emissionCost, excessEmission, endDateTime,
            returnsAfterEnd, fixedDividends, startDateTime,
          } = targetAsset || sourceAsset;

          if (amount % sharePrice !== 0) {
            throw Error(`The amount has to be a total of shares bought (price per share: ${sharePrice} eur).`);
          }

          // If there was an old payment we might need these values to restore the correct amounts
          const oldAmount = Number(sourcePayment.providerData.metadata.euroAmount);
          const oldSharesBought = oldAmount / sourceAsset.sharePrice;

          const amountIsDifferent = oldAmount !== amount;

          // If the asset is unchanged for a modify payment we calculate the difference of the new amount and the old one (can be negative)
          const amountDiff = differentAsset ? amount : amount - oldAmount;
          const sharesDiff = amountDiff / sharePrice;
          const newShares = amount / sharePrice;

          if (sharesDiff > sharesAvailable) {
            throw Error('The asset does not have this many available shares.');
          }

          if ((!targetInvestment?.data.paidEuroTotal || (oldAmount !== sourceInvestment.paidEuroTotal)) && amount < euroMin) {
            throw Error(`The amount has to be higher than the minimum (${euroMin} eur).`);
          }

          if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime) < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
          } else if (!dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const dateNow = firebase.firestore.Timestamp.now();

          const newPayment: Payment = {
            ...sourcePayment,
            paymentDateTime: paymentDate,
            dividendsFormat: !fixedDividends ? dividendsFormat! : [years.toString(), returnsAfterEnd!],
            updatedDateTime: dateNow,
            ...(paymentEndDateTime && { endDateTime: firebase.firestore.Timestamp.fromMillis(paymentEndDateTime) }),
            ...(type && { type }),
          };

          if (amountIsDifferent) {
            // Adding the emission cost on top of the euro amount
            let applicableEmission = emissionCost;
            // Use the excess emission percentage only if it is provided and the amount exceeds its threshold
            if (excessEmission && amount >= excessEmission.threshold) {
              applicableEmission = excessEmission.percentage;
            }
            const amountWithEmissionCost = amount * ((100 + applicableEmission) / 100);

            newPayment.providerData.amount.value = ceilNumber(amountWithEmissionCost, 2);
            newPayment.providerData.metadata.euroAmount = amount;
            newPayment.providerData.metadata.sharesAmount = newShares;
          }

          /**
           * TRANSACTION UPDATE / SET ZONE
           */
          if (differentInvestment) {
            if (targetInvestment) {
              newInvestmentRef = investmentsRef.doc(targetInvestment.id);
              // Update target investment
              transaction.update(
                newInvestmentRef,
                {
                  paidEuroTotal: firebase.firestore.FieldValue.increment(amountDiff),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesDiff),
                  updatedDateTime: dateNow,
                },
              );

              // Create target payment
              newPaymentRef = investmentsRef.doc(targetInvestment.id).collection('payments').doc();
              transaction.set(
                newPaymentRef,
                {
                  ...newPayment,
                  investor: investorRef,
                  investment: newInvestmentRef,
                  asset: assetRef,
                  id: newPaymentRef.id,
                } as Payment,
              );
            } else {
              // Create target investment
              newInvestmentRef = investmentsRef.doc();
              transaction.set(
                newInvestmentRef,
                {
                  paidEuroTotal: firebase.firestore.FieldValue.increment(amountDiff),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesDiff),
                  asset: assetRef,
                  investor: investorRef,
                  createdDateTime: sourceInvestment.createdDateTime,
                  updatedDateTime: dateNow,
                },
              );

              // Create target payment
              newPaymentRef = newInvestmentRef.collection('payments').doc();
              transaction.set(
                newPaymentRef,
                {
                  ...newPayment,
                  investor: investorRef,
                  asset: assetRef,
                  investment: newInvestmentRef,
                  id: newPaymentRef.id,
                } as Payment,
              );
            }

            if (differentAsset) {
              // Restore source asset
              transaction.update(
                assetsRef.doc(sourceInvestment.asset.id),
                {
                  sharesAvailable: firebase.firestore.FieldValue.increment(oldSharesBought),
                  updatedDateTime: dateNow,
                },
              );
            }

            // Update target asset
            transaction.update(
              assetRef,
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(-sharesDiff),
                updatedDateTime: dateNow,
              },
            );

            // Delete old payment
            transaction.delete(paymentRef);

            // If no payments left, we remove otherwise just decrease
            if ((sourceInvestment.paidEuroTotal! - oldAmount) === 0) {
              transaction.delete(investmentRef);
            } else {
              transaction.update(
                investmentRef,
                {
                  paidEuroTotal: firebase.firestore.FieldValue.increment(-oldAmount),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(-oldSharesBought),
                  updatedDateTime: dateNow,
                },
              );
            }
          } else {
            // Update target investment
            transaction.update(
              investmentRef,
              {
                paidEuroTotal: firebase.firestore.FieldValue.increment(amountDiff),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesDiff),
                updatedDateTime: dateNow,
              },
            );

            // Update target payment
            transaction.update(
              paymentRef,
              newPayment,
            );

            // Update target asset
            transaction.update(
              assetsRef.doc(assetId),
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(-sharesDiff),
                updatedDateTime: dateNow,
              },
            );
          }

          // Return the target
          return { investmentId: newInvestmentRef.id, paymentId: newPaymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updatePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'updatePayment' });
    },
    async deletePayment(
      { commit },
      { investmentId, paymentId }:
        { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deletePayment' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const assetId = readInvestmentSuccess.get('asset').id as string;
        const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const payment = readPaymentSuccess.data() as Payment;

        if (payment.deleted) {
          throw Error('This payment was already deleted.');
        }

        const [readAsset, readAssetSuccess] = await to(transaction.get(assetRef));
        if (readAsset || !readAssetSuccess?.exists) {
          throw readAsset || Error('Error getting the asset.');
        }

        if (payment.providerData.status === PaymentStatus.Open) {
          throw Error('Cannot close an open payment.');
        }

        const timeNow = firebase.firestore.Timestamp.now();

        // Only restore the share fields if the payment was already paid (and not ended yet). Otherwise the paymentWebHook already took care of it
        if (payment.providerData.status === PaymentStatus.Paid && !payment.ended) {
          const amount = payment.providerData.metadata.euroAmount;
          const sharesAmount = (amount / readAssetSuccess.get('sharePrice'));

          // Update asset (only necessary if removing a payment with a paid status)
          transaction.update(
            assetRef,
            { sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount), updatedDateTime: timeNow },
          );

          // Update investment with new totals
          transaction.update(
            investmentRef,
            {
              paidEuroTotal: firebase.firestore.FieldValue.increment(-amount),
              boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              updatedDateTime: timeNow,
            },
          );
          // Update counts
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            { paidPayments: firebase.firestore.FieldValue.increment(-1) },
          );
        }

        // Removing payment
        transaction.update(paymentRef, { deleted: true, updatedDateTime: timeNow });
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deletePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'deletePayment' });
    },
    async endPayment(
      { commit },
      { investmentId, paymentId, endDate }:
        { investmentId: string, paymentId: string, endDate?: number },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'endPayment' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const assetId = readInvestmentSuccess.get('asset').id as string;
        const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const payment = readPaymentSuccess.data() as Payment;

        if (payment.ended) {
          throw Error('This payment was already ended.');
        }

        const [readAsset, readAssetSuccess] = await to(transaction.get(assetRef));
        if (readAsset || !readAssetSuccess?.exists) {
          throw readAsset || Error('Error getting the asset.');
        }

        const startDateTime = readAssetSuccess.get('startDateTime') as firebase.firestore.Timestamp;

        if (payment.providerData.status === PaymentStatus.Open) {
          throw Error('Cannot close an open payment.');
        }

        const timeNow = firebase.firestore.Timestamp.now();

        if (startDateTime && new Date(endDate || timeNow.toMillis()) < startDateTime.toDate()) {
          throw new Error('The payment cannot be ended before the start date of the selected asset.');
        }

        // Only restore the share fields if the payment was already paid. Otherwise the paymentWebHook already took care of it
        if (payment.providerData.status === PaymentStatus.Paid && !payment.ended) {
          const amount = payment.providerData.metadata.euroAmount;
          const sharesAmount = (amount / readAssetSuccess.get('sharePrice'));

          // Update asset (only necessary if removing a payment with a paid status)
          transaction.update(
            assetRef,
            { sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount), updatedDateTime: timeNow },
          );

          // Update investment with new totals
          transaction.update(
            investmentRef,
            {
              paidEuroTotal: firebase.firestore.FieldValue.increment(-amount),
              boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              updatedDateTime: timeNow,
            },
          );
        }

        // Ending payment
        transaction.update(
          paymentRef,
          {
            ended: endDate ? firebase.firestore.Timestamp.fromMillis(endDate) : timeNow,
            updatedDateTime: timeNow,
          },
        );

        // Update counts
        transaction.update(
          bloqifyFirestore.collection('settings').doc('counts'),
          { paidPayments: firebase.firestore.FieldValue.increment(-1) },
        );
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'endPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'endPayment' });
    },
  },
  getters: {},
};
