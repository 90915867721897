import Vue from 'vue';
// @ts-ignore
import vueHeadful from 'vue-headful';

// @ts-ignore
import VueMoment from 'vue-moment';
import IdleVue from 'idle-vue';
import moment from 'moment';
// Library to create slugs
// @see https://github.com/dodo/node-slug
import slug from 'slug';
import { ClientTable } from 'vue-tables-2';
import VueUploadComponent from 'vue-upload-component';
import * as VueGoogleMaps from 'vue2-google-maps';
import LazyLoad from 'vue-lazyload';
import { Promised } from 'vue-promised';
import vSelect from 'vue-select';
import { init as SentryInit } from '@sentry/browser';
import { Vue as VueSentry } from '@sentry/integrations';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-select/src/scss/vue-select.scss';
import store from '@/store';

const env = process.env;
const NODE_ENV = env.NODE_ENV;
const isDevelopmentEnvironment = NODE_ENV === 'development';
const isProductionEnvironment = NODE_ENV === 'production';

// Make globally available as $slug(...) in
// templates and as this.$slug(...) in TypeScript
Object.defineProperty(Vue.prototype, '$slug', { value: slug });

Vue.use(ClientTable);

// Vue file upload component
Vue.component('file-upload', VueUploadComponent);

// Library to set page titles
// @see https://github.com/troxler/vue-headful
Vue.component('vue-headful', vueHeadful);

// Library for handling dates and times
// @see https://github.com/brockpetrie/vue-moment
// @see http://momentjs.com/docs/#/use-it/
Vue.use(VueMoment, { moment });
moment.locale('en');

// Changing moment locale
window.addEventListener('storage', (event): void => {
  if (event.storageArea === localStorage && localStorage.getItem('vue-i18n-language')
    && localStorage.getItem('vue-i18n-language') !== moment.locale()) {
    moment.locale(localStorage.getItem('vue-i18n-language')!);
  }
}, false);

Vue.use(LazyLoad);

// Library to handle loading
// @see https://github.com/posva/vue-promised
Vue.component('Promised', Promised);

// @ts-ignore
Vue.component('v-select', vSelect);

Vue.use(IdleVue, {
  store,
  // 3 hours at dev, 30 minutes at prod
  idleTime: isDevelopmentEnvironment ? 10800000 : 1800000,
});

// Google maps config
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_BLOQADMIN_FIREBASE_API_KEY,
    libraries: 'places',
  },
});

if (isProductionEnvironment) {
  SentryInit({
    dsn: process.env.VUE_APP_BLOQADMIN_SENTRY,
    integrations: [
      new VueSentry({
        Vue: Vue as any,
        attachProps: true,
        logErrors: isDevelopmentEnvironment,
      }),
    ],
  });
}
