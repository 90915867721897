import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { Dividend } from '@/models/assets/Dividends';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';

const SET_DIVIDENDS = 'SET_DIVIDENDS';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_DIVIDENDS](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addDividend(
      { commit },
      {
        amount, assetId, period,
      }:
      {
        amount: number, assetId: string, period: Date,
      },
    ): Promise<void> {
      commit(SET_DIVIDENDS, { status: DataContainerStatus.Processing, operation: 'addDividend' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const newDividendRef = assetRef.collection('dividends').doc();

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(amount),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
        transaction.set(newDividendRef, {
          amount,
          asset: assetRef,
          period: firebase.firestore.Timestamp.fromDate(period),
          createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, { status: DataContainerStatus.Error, payload: transactionError, operation: 'addDividend' });
      }

      return commit(SET_DIVIDENDS, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'addDividend' });
    },
    async updateDividend(
      { commit },
      {
        amount, assetId, dividendId, period,
      }:
      {
        amount: number, assetId: string, dividendId: string, period: Date,
      },
    ): Promise<void> {
      commit(SET_DIVIDENDS, { status: DataContainerStatus.Processing, operation: 'updateDividend' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const dividendRef = assetRef.collection('dividends').doc(dividendId);

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getDividendError, getDividend] = await to(dividendRef.get());

        if (getDividendError) {
          commit(SET_DIVIDENDS, { status: DataContainerStatus.Error, payload: getDividendError, operation: 'updateDividend' });
          return;
        }

        const prevDividend = getDividend?.data() as Dividend;
        const amountDiff = amount - prevDividend.amount;

        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(amountDiff),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
        transaction.update(dividendRef, {
          amount,
          period: firebase.firestore.Timestamp.fromDate(period),
          createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateDividend' });
      }

      return commit(SET_DIVIDENDS, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'updateDividend' });
    },
  },
};
