import { ActionContext, Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Investor, isInvestor, User, UserStatus, UserTier } from '@/models/users/User';
import {
  IdentificationRequest,
  IdentificationRequestStatus,
} from '@/models/identification-requests/IdentificationRequest';
import { Idin } from '@/models/identification-requests/idin';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';
import { generateFileMd5Hask } from '../utils/files';

const SET_USER = 'SET_USER';

/*
* unfortunately it is not possible to extend enums directly
* thus we create an object combining the enums
* but an object is not a type too in TS thus the type is declared explicitly
* on import both are imported automatically
*/
enum StatusRest {
  Error = 'error',
  None = 'none',
}

export const GetUserIdentificationStatus = { ...IdentificationRequestStatus, ...StatusRest };
export type GetUserIdentificationStatus = IdentificationRequestStatus | StatusRest;

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_USER](
      state,
      { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async handleUserStatus(
      { commit }: ActionContext<Vertebra, State>,
      { id, status, statusMessage }: { id: string, status: User['status'], statusMessage?: User['statusMessage'] },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'handleUserStatus' });

      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const [transactionUpdateError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const documentRef = bloqifyFirestore.collection('investors').doc(id);
        const [readUser, readUserSuccess] = await to(transaction.get(documentRef));
        if (readUser || !readUserSuccess?.exists) {
          throw readUser || Error('Error getting the payment.');
        }

        transaction.update(
          documentRef,
          {
            status,
            ...statusMessage && { statusMessage },
            updatedDateTime: serverTimestamp,
          },
        );

        const investorCounter = (readUserSuccess.data() as User).tier === UserTier.Investor ? 1 : 0;
        transaction.update(
          bloqifyFirestore.collection('settings').doc('counts'),
          {
            activeUsers: firebase.firestore.FieldValue.increment(status === UserStatus.Disabled ? -1 : 1),
            activeInvestors: firebase.firestore.FieldValue.increment(status === UserStatus.Disabled ? -investorCounter : investorCounter),
            updatedDateTime: serverTimestamp,
          },
        );
      }));

      if (transactionUpdateError) {
        commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: transactionUpdateError,
          operation: 'handleUserStatus',
        });
        return;
      }

      commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: { status },
        operation: 'handleUserStatus',
      });
    },
    async createUser(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { password: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'createUser' });

      const { email, password, ...restOfInvestor } = user;

      const [createFirebaseUser, createUserSuccess] = await to(bloqifyFunctions.httpsCallable('createUser')({
        email,
        password,
      }));
      if (createFirebaseUser) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: createFirebaseUser,
          operation: 'createUser',
        });
      }

      const id = createUserSuccess?.data.uid;

      if (!id) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user id.'),
          operation: 'createUser',
        });
      }

      const storageRef = bloqifyStorage.ref();
      const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);
      const fileHandler = async (filePath: string): Promise<void> => {
        const file = restOfInvestor[filePath] as File;
        if (file) {
          const md5Hash = await generateFileMd5Hask(file, true);
          const path = `investors/${id}/${filePath}.${getExtension(file.type)}`;
          const fileRef = storageRef.child(path);
          restOfInvestor[filePath] = path;
          await fileRef.put(file, { customMetadata: { md5Hash } });
        }
      };

      try {
        await Promise.all([
          fileHandler('passport'),
          fileHandler('kvkImage'),
        ]);
      } catch (e) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: e,
          operation: 'updateUser',
        });
      }

      if (createUserSuccess) {
        const [createUserError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          transaction.set(bloqifyFirestore.collection('investors').doc(id), {
            ...restOfInvestor,
            email,
            status: UserStatus.Enabled,
            createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          } as User);
          transaction.update(bloqifyFirestore.collection('settings').doc('counts'), {
            activeInvestors: firebase.firestore.FieldValue.increment(1),
            // no need to update activeUser since that is done in the CF
          });
        }));
        if (createUserError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: createUserError,
            operation: 'createUser',
          });
        }
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: id,
        operation: 'createUser',
      });
    },
    /**
     * Update function that handles also the firebase auth email change.
     */
    async updateUser(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { uid: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateUser' });

      const { uid, ...restOfInvestor } = user;
      const userRef = bloqifyFirestore.collection('investors').doc(uid);

      const storageRef = bloqifyStorage.ref();
      const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);
      const fileHandler = async (filePath: string): Promise<any> => {
        const file = restOfInvestor[filePath] as File;
        if (file) {
          const md5Hash = await generateFileMd5Hask(file, true);
          const path = `investors/${uid}/${filePath}.${getExtension(file.type)}`;
          const fileRef = storageRef.child(path);
          restOfInvestor[filePath] = path;
          return fileRef.put(file, { customMetadata: { md5Hash } });
        }
        return (): void => undefined;
      };

      // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
      // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
      // what kind of error we are getting from the rules, only no permission.
      let storageResultsAndErrors: firebase.storage.UploadTaskSnapshot | firebase.functions.HttpsError[];
      try {
        storageResultsAndErrors = await Promise.all([
          fileHandler('passport'),
          fileHandler('kvkImage'),
        ]);
      } catch (e) {
        if (e.code === 'storage/unauthorized') {
          console.error(`${e.message} => Likely this file already exists`);
        } else {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: e,
            operation: 'updateUser',
          });
        }
      }

      // The call to the Bloqify cloud function (Admin SDK) is only neccessary if the email changed
      const [getUserError, userSnapshot] = await to(userRef.get());
      if (getUserError || !userSnapshot) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user.'),
          operation: 'updateUser',
        });
      }
      if (userSnapshot.get('email') !== user.email) {
        const [updateUserError] = await to(bloqifyFunctions.httpsCallable('updateUserEmail')({
          uid,
          email: user.email,
        }));

        if (updateUserError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: updateUserError,
            operation: 'updateUser',
          });
        }
      }

      const [updateUserError] = await to(userRef.update(
        {
          ...restOfInvestor,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
      ));
      if (updateUserError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: updateUserError,
          operation: 'updateUser',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: user,
        operation: 'updateUser',
      });
    },
  },
  getters: {
    getUserIdentificationStatus:
      (
        state,
        getters,
        { boundUser },
      ): Function => (user: User | undefined): GetUserIdentificationStatus => {
        const tempUser = user || boundUser;
        if (!tempUser) {
          return GetUserIdentificationStatus.None;
        }
        if (isInvestor(tempUser)) {
          return IdentificationRequestStatus.Approved;
        }

        // check if IDIN nor Ir then no ID request was made
        if (!(tempUser.idin || tempUser.identificationRequest)) {
          return GetUserIdentificationStatus.None;
        }

        // if IDIN we've got an error if the status is not Success
        if (tempUser.idin) {
          return (tempUser.idin as Idin).Transaction.status === 'Success' ? GetUserIdentificationStatus.Approved : GetUserIdentificationStatus.Error;
        }

        // if IR use that status
        if (tempUser.identificationRequest) {
          return (tempUser.identificationRequest as IdentificationRequest).status || GetUserIdentificationStatus.None;
        }

        return GetUserIdentificationStatus.Error;
      },
  },
};
